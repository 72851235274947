import MachineEnquiryForm from "../../forms/MachineEnquiryForm";

const BodyContent = () => {
  return (
    <>
      <MachineEnquiryForm />
    </>
  );
};

export default BodyContent;
