import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyCIC98wh8ZxfLNK7jmMHOiBpsUjwO8_ts0",
  authDomain: "munjiri-merchants-9273c.firebaseapp.com",
  projectId: "munjiri-merchants-9273c",
  storageBucket: "munjiri-merchants-9273c.appspot.com",
  messagingSenderId: "528658724386",
  appId: "1:528658724386:web:0a1cdbdcb1b0f6b0d67c7b",
  measurementId: "G-VGSJSE1L4F",
};

const app = initializeApp(firebaseConfig);

// gives us an auth instance
const auth = getAuth(app);

// in order to use this auth instance elsewhere
export default auth;
